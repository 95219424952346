import React, { useEffect, useRef, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Blog from "./components/blog";
import Footer from "./components/footer";
import Icons from "./components/icons";
import Header from "./header";

import Contact from "../contactUs/contact";

import selling from "../../assets/images/now selling.jpg";

import closeX from "../../assets/images/close-x.svg";
import PopUp from "./components/pop_1";
export default function Home() {
	const location = useLocation();
	const recent_project = useRef();
	let scrollTimeout;
	const [show, setShow] = useState(true);
	const iconsRef = useRef();
	useEffect(() => {
		function Scroll() {
			if (
				iconsRef.current != null &&
				iconsRef.current.style.display == "none"
			) {
				iconsRef.current.style.display = "block";
			}
			clearTimeout(scrollTimeout);
			scrollTimeout = setTimeout(() => {
				iconsRef.current.style.display = "none";
			}, 2000);
		}
		window.addEventListener("scroll", Scroll);

		let config = { attributes: true, childList: true };
		function callback(multation) {
			multation[0].target.animate(
				[
					// keyframes
					{ transform: "translateY(0px)" },
					{ transform: "translateY(20px)" },
					{ transform: "translateY(15px)" },
					{ transform: "translateY(10px)" },
					{ transform: "translateY(5px)" },
					{ transform: "translateY(0px)" },
					{ transform: "translateY(-5px)" },
					{ transform: "translateY(-10px)" },
				],
				{
					// timing options
					duration: 500,
					iterations: 1,
				}
			);
		}

		let newobserver = new MutationObserver(callback);
		setTimeout(() => {
			newobserver.observe(iconsRef.current, config);
		}, 200);

		setTimeout(() => {
			recent_project.current.classList.toggle("d-none");
		}, 3000);

		return () => {
			window.removeEventListener("scroll", Scroll);
		};
	}, []);

	function closeRecentComponentBar() {
		recent_project.current.classList.toggle("d-none");
		setShow(() => false);
	}
	return (
		<div>
			<div className="">
				<div
					className="social-media"
					ref={iconsRef}
				>
					<Icons title="" />
				</div>
				<Header />
			</div>
			{/* {show && (
				<PopUp
					recent_project={recent_project}
					closeRecentComponentBar={closeRecentComponentBar}
				/>
			)} */}

			<main>
				<Outlet />
			</main>
			{location.pathname != "/" ? "" : <Contact />}

			{location.pathname != "/" ? "" : <Blog />}
			<Footer />
		</div>
	);
}
